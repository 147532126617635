<template>
<div>
    <rs-dialog :visible.sync="visible" :title="title" :right="true">
        <h5>
        <small>名称:</small> {{statementInfo.name}}
        <small>  金额:</small> {{statementInfo.money}}
        <small>  日期:</small> {{statementInfo.time}}
        </h5>
        <rs-table-striped :data="data" :column="column">
          <template slot-scope="scope">
            <td class="td-actions text-right">
              <rs-button @click="delConnect(scope.row.id)" is_sm>取消关联</rs-button>
              <rs-button color="rose" @click="deletePay(scope.row.id)" is_sm>删除</rs-button>
            </td>
          </template>
            <tr slot="count">
              <td colspan="2">
                <span class="btn btn-info btn-round btn-file">
                  <span class="fileinput-new">选择图片</span>
                  <input type="file" @change="upload" />
                </span>
                <div contenteditable="true" @paste="pasteImg($event)" class="textareaSty">粘贴图片</div>
              </td>
              <td class="td-total">
                剩余
              </td>
              <td class="td-price">
                <small>￥</small>{{statementInfo.timeMoney}}
              </td>
              <td class="td-actions text-right">
                <rs-button @click="$refs.pay.handle(statementInfo.id,0)">添加关联</rs-button>
              </td>
            </tr>
        </rs-table-striped>
        <rs-row>
          <rs-col :lg="6" :md="6" :sm="6" v-for="(v,i) in statementInfo.imageList" :key="i">
            <a :href="v.img" target="_blank">
              <img :src="v.img + '?x-oss-process=style/smart'" />
            </a>
          </rs-col>
        </rs-row>
        <rs-table :data="noConnectList" :column="{typeName:'名称',money:'金额'}">
          <template slot-scope="scope">
            <td class="text-right">
              <rs-button is_sm @click="addConnect(scope.row.id)">添加</rs-button>
            </td>
          </template>
        </rs-table>
        <pay ref="pay" @statementUpdate="updateItem"></pay>
  </rs-dialog>
</div>
</template>

<script>
import pay from './curd/pay.vue'
export default {
  components: { pay },
  data () {
    return {
      title: '账目关联处理',
      visible: false,
      id: 0,
      statementInfo: { name: '', money: '', time: '', imageList: [], payList: [] },
      column: { typeName: '主体', name: '名义', money: '金额', time: '支付日期' },
      data: [{ typeName: '', money: '', tmp: '' }],
      noConnectList: []
    }
  },
  mounted () {
    this.getConnectList()
  },
  methods: {
    getStatement () {
      this.$api.statement.index({ id: this.id }, function (data) {
        this.statementInfo = data.data
        this.data = data.data.payList ? data.data.payList : []
      }.bind(this))
    },
    getConnectList () {
      this.$api.pay.noConnectList({}, function (data) {
        this.noConnectList = data.data
      }.bind(this))
    },
    addConnect (id) {
      this.$api.pay.addConnect({ bid: this.statementInfo.id, id: id }, function (data) {
        console.log(data.data)
        this.getConnectList()
        this.getStatement()
        this.$emit('statementUpdate')
      }.bind(this))
    },
    delConnect (id) {
      this.$api.pay.delConnect({ id: id }, function (data) {
        console.log(data.data)
        this.getConnectList()
        this.getStatement()
        this.$emit('statementUpdate')
      }.bind(this))
    },
    handle (id) {
      this.id = id
      this.visible = true
      this.getStatement()
    },
    updateItem () {
      this.getStatement()
      this.$emit('statementUpdate')
    },
    upload (image) {
      this.saveImg(image.target.files[0])
      this.$tools.upload.alioss(
        image.target.files[0], 'Baihui/bill/',
        function (result) {
          const url = 'https://redscn.oss-cn-hangzhou.aliyuncs.com/' + result.name
          this.$api.billImage.insert(
            { bid: this.id, img: url },
            function (data) {
              this.getStatement()
            }.bind(this)
          )
        }.bind(this)
      )
    },
    pasteImg(e) {
      const { items } = e.clipboardData
      if (items.length) {
        for (const item of items) {
          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile()
            this.$tools.upload.alioss(
              file, 'Baihui/bill/',
              function (result) {
                const url = 'https://redscn.oss-cn-hangzhou.aliyuncs.com/' + result.name
                this.$api.billImage.insert(
                  { bid: this.id, img: url },
                  function (data) {
                    this.statementInfo.imageList.push(data.data)
                  }.bind(this)
                )
              }.bind(this)
            )
          }
        }
      }
    },
    deletePay (id) {
      this.$api.pay.del({ id: id }, function (data) {
        if (data.code === 200) {
          this.getStatement()
        }
      }.bind(this))
    }
  }
}
</script>

<style>

</style>

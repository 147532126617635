<template>
  <rs-dialog :visible.sync="visible" :title="title">
    <rs-form :model="pay" ref="Form" @submit.native="submit">
      <!-- <rs-form-item label="类型" prop="type" :class="{'is-filled':pay.type}" :rules="[{required:true,message:'名称必填'}]">
              <rs-input v-model="pay.type"></rs-input>
          </rs-form-item>
          <rs-form-item label="类型id" prop="tid" :class="{'is-filled':pay.tid}" :rules="[{required:true,message:'名称必填'}]">
              <rs-input v-model="pay.tid"></rs-input>
          </rs-form-item> -->
      <div class="block">
        <el-cascader :options="options" :props="{ checkStrictly: true }" filterable @change="handleChange"
          style="width:100%"></el-cascader>
      </div>
      <rs-form-item label="名义" prop="name" :class="{ 'is-filled': pay.name }"
        :rules="[{ required: true, message: '名称必填' }]">
        <rs-input v-model="pay.name"></rs-input>
      </rs-form-item>
      <rs-form-item label="金额" prop="money" :class="{ 'is-filled': pay.money }"
        :rules="[{ required: true, message: '金额必填' }]">
        <rs-input v-model="pay.money"></rs-input>
      </rs-form-item>
      <rs-form-item label="日期" prop="time" :rules="[{ required: true, message: '日期必填' }]" class="is-focused">
        <rs-input v-model="pay.time" type="date"></rs-input>
      </rs-form-item>
      <rs-form-item>
        <rs-button color="info" type="submit">提交</rs-button>
      </rs-form-item>
    </rs-form>
  </rs-dialog>
</template>

<script>
import request from '@/utility/request'
export default {
  data() {
    return {
      visible: false,
      id: null,
      pay: { bid: '', type: '', tid: '', money: '', name: '', time: '' },
      title: '',
      key: 1,
      options: []
    }
  },
  methods: {
    handleChange(value) {
      if (value.length < 2) {
        return
      }
      switch (value[0]) {
        case 'tag':
          this.pay.type = value[0]
          this.pay.tid = value[value.length - 1]
          break
        case 'custom':
          this.pay.tid = value[value.length - 1]
          if (value.length < 3) {
            this.pay.type = value[0]
          } else {
            this.pay.type = 'order'
          }
          break
      }
    },
    handle(bid, id) {
      this.id = id
      this.pay = { bid: '', type: '', tid: '', money: '', name: '', time: '' }
      if (bid !== 0) {
        this.pay.bid = bid
      }
      if (id === 0) {
        this.title = '添加账目'
      } else {
        this.title = '编辑账目'
        this.$api.pay.edit({ id: id }, function (data) {
          this.pay = data.data
        }.bind(this))
      }
      request.get('https://sailing.sailingcn.top/api/tag/allList').then(data => {
        console.log(this.options = data.data)
      })
      this.visible = true
    },
    submit(e) {
      e.preventDefault()
      this.$refs.Form.validate(isValidate => {
        if (isValidate) {
          if (this.id !== 0) {
            this.$api.pay.update(this.pay, function (data) {
              if (data.code === 200) {
                this.$emit('statementUpdate', 'statement')
                this.visible = false
                this.$tools.notify.toast('成功了', '更新账目成功', 'success')
              }
            }.bind(this))
          } else {
            this.$api.pay.insert(this.pay, function (data) {
              if (data.code === 200) {
                this.$emit('statementUpdate', 'statement')
                this.visible = false
                this.$tools.notify.toast('成功了', '添加账目成功', 'success')
              }
            }.bind(this))
          }
          this.visible = false
        } else {
        }
      })
    }
  }
}
</script>

<template>
    <rs-dialog :visible.sync="visible" :title="title">
      <rs-form :model="statement" ref="Form" @submit.native="submit">
          <rs-form-item label="名称" prop="name" :class="{'is-filled':statement.name}" :rules="[{required:true,message:'名称必填'}]">
              <rs-input v-model="statement.name"></rs-input>
          </rs-form-item>
          <rs-form-item label="金额" prop="money" :class="{'is-filled':statement.money}" :rules="[{required:true,message:'金额必填'}]">
              <rs-input v-model="statement.money"></rs-input>
          </rs-form-item>
          <rs-form-item label="日期" prop="time" :rules="[{required:true,message:'日期必填'}]" class="is-focused">
              <rs-input v-model="statement.time" type="date"></rs-input>
          </rs-form-item>
          <rs-form-item label="账户名" class="is-focused">
              <rs-input v-model="statement.cardName"></rs-input>
          </rs-form-item>
          <rs-form-item label="账号" class="is-focused">
              <rs-input v-model="statement.cardId"></rs-input>
          </rs-form-item>
          <rs-form-item>
              <rs-button color="info" type="submit">提交</rs-button>
          </rs-form-item>
      </rs-form>
      {{statement}}
    </rs-dialog>
</template>

<script>

export default {
  data () {
    return {
      visible: false,
      id: null,
      statement: { bid: '', name: '', money: '', time: '' },
      title: ''
    }
  },
  methods: {
    handle (id, bid = 0) {
      this.id = id
      this.statement = { bid: '', name: '', money: '', time: '' }
      if (bid !== 0) {
        this.statement.bid = bid
      }
      if (id === 0) {
        this.title = '添加账目'
        var myDate = new Date()
        this.statement.time = myDate.getFullYear() + '-' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '-' + ('0' + myDate.getDate()).slice(-2)
      } else {
        this.title = '编辑账目'
        this.$api.statement.edit({ id: id }, function (data) {
          this.statement = data.data
        }.bind(this))
      }

      this.visible = true
    },
    submit (e) {
      e.preventDefault()
      this.$refs.Form.validate(isValidate => {
        if (isValidate) {
          if (this.id !== 0) {
            this.$api.statement.update(this.statement, function (data) {
              if (data.code === 200) {
                this.$emit('statementUpdate', 'statement')
                this.visible = false
                this.$tools.notify.toast('成功了', '更新账目成功', 'success')
              }
            }.bind(this))
          } else {
            this.$api.statement.insert(this.statement, function (data) {
              if (data.code === 200) {
                this.$emit('statementUpdate')
                this.visible = false
                this.$tools.notify.toast('成功了', '添加账目成功', 'success')
              }
            }.bind(this))
          }
          this.visible = false
        } else {
        }
      })
    }
  }
}
</script>

<template>
    <rs-dialog :visible.sync="visible" :title="title">
      <rs-form :model="statements" ref="Form" @submit.native="submit">
          <rs-form-item label="名称" prop="name" :class="{'is-filled':statements.name}" :rules="[{required:true,message:'名称必填'}]">
              <rs-input v-model="statements.name"></rs-input>
          </rs-form-item>
          <rs-form-item label="锁" prop="state" :class="{'is-filled':statements.state!=null}" :rules="[{required:true,message:'名称必填'}]">
              <rs-input v-model="statements.state" :class="{disabled:!statements.id}"></rs-input>
          </rs-form-item>
          <rs-form-item label="日期" prop="time" :rules="[{required:true,message:'日期必填'}]" class="is-focused">
              <rs-input v-model="statements.time" type="date"></rs-input>
          </rs-form-item>
          <rs-form-item>
              <rs-button color="info" type="submit">提交</rs-button>
          </rs-form-item>
      </rs-form>
      {{statements}}
    </rs-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      id: null,
      item: null,
      statements: { name: '', state: '', time: '' },
      title: ''
    }
  },
  methods: {
    handle (id, item) {
      this.id = id
      this.item = item
      this.statements = { name: '', state: 0, time: '' }
      if (id === 0) {
        this.title = '添加账套'
        var myDate = new Date()
        this.statements.time = myDate.getFullYear() + '-' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '-' + ('0' + myDate.getDate()).slice(-2)
      } else {
        this.title = '编辑账套'
        this.$api.statements.edit({ id: id }, function (data) {
          if (data.code === 200) {
            this.statements = data.data
          }
        }.bind(this))
      }
      this.visible = true
    },
    submit (e) {
      e.preventDefault()
      this.$refs.Form.validate(isValidate => {
        if (isValidate) {
          if (this.id !== 0) {
            this.$api.statements.update(this.statements, function (data) {
              if (data.code === 200) {
                this.$emit('statementsUpdate')
                this.visible = false
                this.$tools.notify.toast('成功了', '更新账套成功', 'success')
              }
            }.bind(this))
          } else {
            this.$api.statements.insert(this.statements, function (data) {
              if (data.code === 200) {
                this.$emit('statementsUpdate')
                this.visible = false
                this.$tools.notify.toast('成功了', '添加账套成功', 'success')
              }
            }.bind(this))
          }
          this.visible = false
        } else {
        }
      })
    }
  }
}
</script>

<template>
  <rs-card title="账务报表">
    <template slot="tools">
      <rs-button style="margin:0;padding:5px" color="info" @click="$refs.bills.handle(0)">添加账套</rs-button>
    </template>
    <div id="accordion" role="tablist">
      <div class="card-collapse" v-for="(value, index) in statements" :key="index">
        <div class="card-header">
          <h5 class="mb-0">
            <a data-toggle="collapse" :href="'#collapse' + index">
              {{ value.name }} (结余:{{ value.endMoney }}) (日期:{{ value.time }})
              <i class="material-icons">keyboard_arrow_down</i>
            </a>
          </h5>
        </div>
        <div :id="'collapse' + index" class="collapse" data-parent="#accordion">
          <div class="card-body">
            <rs-table-striped :data="value.billList" :column="column">
              <template slot-scope="scope">
                <td class="td-actions text-right"
                  :style="{ 'background-image': (scope.row.payTimeMoney === 0 ? 'linear-gradient(90deg,transparent,orange)' : '') }">
                  <rs-button color="info" @click="$refs.billInfo.handle(scope.row.id)">详情</rs-button>
                  <rs-button color="info" @click="$refs.bill.handle(scope.row.id)">编辑</rs-button>
                  <rs-button color="rose" @click="billDelete(scope.row.id)">删除</rs-button>
                </td>
              </template>
              <tr slot="count">
                <td colspan="3">
                  <span class="btn btn-info btn-round btn-file">
                    <span class="fileinput-new">选择图片</span>
                    <input type="file" @change="upload($event, value.id)" />
                  </span>
                  <div contenteditable="true" @paste="pasteImg($event, value.id)" class="textareaSty">粘贴图片</div>
                </td>
                <td></td>
                <td class="td-total">
                  结余
                </td>
                <td class="td-price" colspan="2">
                  <small>￥</small>{{ value.timeMoney }}
                </td>
                <td class="td-actions text-right" colspan="2">
                  <rs-button color="info" @click="$refs.bill.handle(0, value.id)">添加细账</rs-button>
                  <rs-button color="info" @click="$refs.bills.handle(value.id)">编辑账套</rs-button>
                  <rs-button color="rose" @click="billsDelete(value.id, index)">删除账套</rs-button>
                </td>
              </tr>
            </rs-table-striped>
            <div>
              <rs-row>
                <rs-col :lg="3" :md="3" :sm="3" v-for="(v, i) in value.imageList" :key="i">
                  <a :href="v.img" target="_blank">
                    <img :src="v.img + '?x-oss-process=style/smart'" />
                  </a>
                </rs-col>
              </rs-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bills ref="bills" @statementsUpdate="updateItem"></bills>
    <bill ref="bill" @statementUpdate="updateItem"></bill>
    <bill-info ref="billInfo" @statementUpdate="updateItem"></bill-info>
  </rs-card>
</template>

<script>
import bills from './curd/bills.vue'
import bill from './curd/bill.vue'
import billInfo from './billInfo.vue'
export default {
  components: { bills, bill, billInfo },
  data() {
    return {
      statements: [],
      column: { id: '序号', time: '时间', name: '名称', money: '金额', payTimeMoney: '对账额度', timeMoney: '实时余额', cardName: '账户名', cardId: '账号' }
    }
  },
  mounted() {
    this.getStatements()
  },
  methods: {
    getStatements() {
      this.$api.statements.index({}, function (data) {
        this.statements = data.data.reverse()
      }.bind(this))
    },
    updateItem() {
      this.getStatements()
    },
    upload(image, id) {
      this.$tools.upload.alioss(
        image.target.files[0], 'Baihui/bills/',
        function (result) {
          const url = 'https://redscn.oss-cn-hangzhou.aliyuncs.com/' + result.name
          this.$api.billsImage.insert(
            { bid: id, img: url },
            function (data) {
              this.getStatements()
            }.bind(this)
          )
        }.bind(this)
      )
    },
    pasteImg(e, id) {
      const { items } = e.clipboardData
      if (items.length) {
        for (const item of items) {
          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile()
            // 上传文件
            this.$tools.upload.alioss(
              file, 'Baihui/bills/',
              function (result) {
                const url = 'https://redscn.oss-cn-hangzhou.aliyuncs.com/' + result.name
                this.$api.billsImage.insert(
                  { bid: id, img: url },
                  function (data) {
                    this.getStatements()
                  }.bind(this)
                )
              }.bind(this)
            )
          }
        }
      }
    },
    billsDelete(id, index) {
      if (this.statements[index].billList.length === 0) {
        this.$api.statements.del({ id: id }, function (data) {
          this.getStatements()
          this.$tools.notify.toast('成功了', '账套删除成功', 'success')
        }.bind(this))
      } else {
        this.$tools.notify.toast('失败了', '账套中存在账目，不能删除', 'warning')
      }
    },
    billDelete(id) {
      this.$tools.swal.del(function () {
        this.$api.statement.del({ id: id }, function (data) {
          if (data.code === 200) {
            this.getStatements()
            this.$tools.notify.toast('成功了', '账目删除成功', 'success')
          }
        }.bind(this))
      }.bind(this))
    }
  }
}
</script>

<style></style>
